import { useEffect, useState } from 'react';
import Layout from '../../components/Common/Layout/Layout';
import bank from '../../assets/bankG.webp';
import upi from '../../assets/upi.png';
import { useNavigate } from 'react-router-dom';
import WithdrawalUPI from '../../components/WithdrawalUPI/WithdrawalUPI';
import WithdrawalBank from '../../components/WithdrawalBank/WithdrawalBank';
import useGetAccountDetails from '../../apis/hooks/useWithdrawalDetailsGet';
import { useCreateWithdrawal } from '../../apis/hooks/useCreateWithdrawal';
import { toast } from 'react-toastify';
import { useUserData } from '../../Context/UserContext';
import Loader from '../../components/Common/Loader/Loader';
import { useGetVisibilityByModule } from '../../apis/hooks/useVisibility';
import sleepingImg from '../../../src/assets/sleeping.avif';
import AlertSection from '../../components/Common/AlertSection/AlertSection';
import { BsBank2 } from 'react-icons/bs';
import { IoArrowBack } from 'react-icons/io5';

const Withdrawal = () => {
    const navigate = useNavigate();
    const [amount, setAmount] = useState('');
    const [expanded, setExpanded] = useState<string | null>(null);
    const [activeTab, setActiveTab] = useState<string>('');
    const [primaryChoice, setPrimaryChoice] = useState<string>('');

    const { user } = useUserData();
    const { data: visibilityData, isLoading: loadingVisibility } =
        useGetVisibilityByModule({
            userId: user?.userdetails?.id,
            name: 'withdraw',
        });

    const { data: accountData, isLoading: loadingAccountDetails } =
        useGetAccountDetails({
            userId: user?.userdetails?.id,
        });

    const { mutate: createWithdrawal, isLoading: isSubmitting } =
        useCreateWithdrawal();

    // const handleChange = (panel: string) => (isExpanded: boolean) => {
    //     setExpanded(isExpanded ? panel : null);
    // };

    // useEffect(() => {
    //     if (accountData?.data?.success) {
    //         setPrimaryChoice(accountData?.data?.data?.primaryChoice);
    //         setActiveTab(accountData?.data?.data?.primaryChoice);
    //     }
    // }, [accountData]);

    const confirmWithdraw = () => {
        const data = {
            amount: +amount,
            userId: user?.userdetails?.id,
        };
        createWithdrawal(data, {
            onSuccess: (res: any) => {
                if (res?.data?.success) {
                    toast(res?.data?.message ?? 'Withdraw Request Submitted', {
                        type: 'success',
                    });
                } else {
                    toast(res?.data?.message ?? 'Try Again', { type: 'error' });
                }
            },
            onError: (error: any) => {
                toast(error?.message ?? 'Something went wrong', {
                    type: 'error',
                });
            },
        });
    };

    if (loadingVisibility) {
        return (
            <Layout>
                <div className="flex items-center justify-center h-screen">
                    <Loader scale={3} />
                </div>
            </Layout>
        );
    }

    if (!visibilityData?.isVisible) {
        return (
            <Layout>
                <div className="flex flex-col gap-4 items-center w-[90%] mx-auto">
                    <h1 className="p-2 font-semibold bg-gray-300 rounded-md">
                        Withdraw Currently Not Available
                    </h1>
                    <p>
                        <span className="font-semibold underline">
                            Reason:{' '}
                        </span>
                        {visibilityData?.reason}
                    </p>
                    <img
                        src={sleepingImg}
                        alt="withdrawal"
                        height={400}
                        width={400}
                    />
                </div>
            </Layout>
        );
    }

    return (
        <Layout>
            <section className="mx-auto w-[90%]">
                <button
                    className="cursor-pointer flex gap-1 text-[#0d6efd] hover:text-white hover:bg-[#0d6efd]  border border-[#0d6efd] items-center rounded-md leading-loose px-2"
                    onClick={() => navigate(-1)}
                >
                    <IoArrowBack />
                    Back
                </button>
                <div className="flex flex-col gap-4 py-2">
                    {loadingAccountDetails ? (
                        <div className="flex items-center justify-center h-20">
                            <Loader scale={2} />
                        </div>
                    ) : (
                        <>
                            <div className="flex flex-col gap-2">
                                <div className="shadow-[0_8px_30px_rgb(0,0,0,0.12)] border border-[#0000002d] rounded-lg ">
                                    <div className="bg-[#f8f9fa] flex justify-center items-center p-2 text-[#212529]">
                                        Select Payment Mode
                                    </div>
                                    <div className="bg-[#6c757d] flex justify-center items-center p-2 text-white">
                                        Withdrawal Chips: ₹
                                        {user?.userdetails?.wallet?.winningCash?.toFixed(
                                            2
                                        ) ?? 0}
                                    </div>
                                    <div className="flex justify-between p-2 text-sm">
                                        <p>Minimum : ₹195</p>
                                        <p>Maximum : ₹10000</p>
                                    </div>
                                </div>

                                <AlertSection
                                    alertType="INFO"
                                    message="Note: Instant Withdrawal in 5 to 10 mins"
                                    className="p-2 bg-[#198754] text-white text-sm"
                                />

                                {!activeTab ? (
                                    <div className="border border-[#dee2e6] rounded px-2 py-4 flex flex-col gap-2">
                                        <div
                                            className="border border-[#dee2e6] rounded p-2 flex items-center gap-2 text-center justify-center hover:bg-[#eee] cursor-pointer"
                                            onClick={() => setActiveTab('UPI')}
                                        >
                                            <img
                                                src={upi}
                                                alt="upi"
                                                className="w-16"
                                            />
                                            <p className="text-[#6e6e6c] font-bold text-xl">
                                                UPI Payment
                                            </p>
                                        </div>
                                        <div
                                            className="border border-[#dee2e6] rounded p-2 flex items-center gap-2 text-center justify-center hover:bg-[#eee] cursor-pointer"
                                            onClick={() => setActiveTab('BANK')}
                                        >
                                            <img
                                                src={bank}
                                                alt="bank"
                                                className="w-16"
                                            />
                                            <p className="text-[#6e6e6c] font-bold text-xl">
                                                BANK / IMPS{' '}
                                                <span className="text-xs ">
                                                    (INSTANT)
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="my-4 shadow-[0_8px_30px_rgb(0,0,0,0.12)] border border-[#0000002d] rounded-lg px-2 py-4 flex flex-col gap-2">
                                        <button
                                            className="text-white cursor-pointer flex gap-1 bg-[#198754]  border border-[#198754] hover:bg-[#157347] items-center rounded-md p-1 text-sm w-max"
                                            onClick={() => setActiveTab('')}
                                        >
                                            <IoArrowBack />
                                            Back
                                        </button>
                                        <div className="p-2 border border-[#0000002d] rounded-lg">
                                            {activeTab === 'UPI' && (
                                                <div className="flex flex-col gap-2">
                                                    <div className="bg-[#f8f9fa] flex justify-center items-center p-2 text-[#212529] border rounded-md border-[#0000002d]">
                                                        UPI Details
                                                    </div>
                                                    <WithdrawalUPI
                                                        data={
                                                            accountData?.data
                                                                ?.data
                                                        }
                                                    />
                                                </div>
                                            )}
                                            {activeTab === 'BANK' && (
                                                <div className="flex flex-col gap-2">
                                                    <div className="bg-[#f8f9fa] flex justify-center items-center p-2 text-[#212529] border rounded-md border-[#0000002d]">
                                                        BANK Details
                                                    </div>
                                                    <WithdrawalBank
                                                        data={
                                                            accountData?.data
                                                                ?.data
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </section>
        </Layout>
    );
};

export default Withdrawal;

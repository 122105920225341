import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import Layout from '../Layout/Layout';
import PrivateRoutes from '../../../Config/PrivateRoute';
import Login from '../../Login/Login';
import Register from '../../Register/Register';
import Maintainance from '../../../pages/Maintainance/maintainance';
import OTP from '../../OTP/OTP';
import LandingPage from '../../../pages/LandingPage/LandingPage';
import PlayArea from '../../../pages/PlayArea/PlayArea';
import Wallet from '../../../pages/Wallet/Wallet';
import History from '../../../pages/History/History';
import Profile from '../../../pages/Profile/Profile';
import AddWallet from '../../../pages/AddWallet/AddWallet';
import PrePayment from '../../../pages/PrePayment/PrePayment';
import Room from '../../../pages/Room/Room';
import IntoPage from '../../../pages/IntoPage/IntoPage';
import ReferAndEarn from '../../../pages/Refer&Earn/Refer&Earn';
import Withdrawal from '../../../pages/Withdrawal/Withdrawal';
import Support from '../../../pages/Support/Support';
import Policy from '../../../pages/Policy/Policy';
import WithdrawalUPI from '../../WithdrawalUPI/WithdrawalUPI';
import WithdrawalBank from '../../WithdrawalBank/WithdrawalBank';
import Kyc from '../../KYC/Kyc';
import UpiPayment from '../../../pages/UpiPayment/UpiPayment';
import QRPayment from '../../../pages/QRPayment/QRPayment';
import PaymentStatus from '../../../pages/PaymentStatus/PaymentStatus';

const Routing = () => {
    // const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true';

    // if (isMaintenanceMode) {
    //     return (
    //         <Suspense fallback={<Layout />}>
    //             <Routes>
    //                 <Route path="*" element={<Maintainance />} />
    //             </Routes>
    //         </Suspense>
    //     );
    // }

    return (
        <Suspense fallback={<Layout />}>
            <Routes>
                {/* <Route path="" element={<IntoPage />}></Route> */}
                <Route
                    path="/login"
                    element={<Login />}
                    // element={<Maintainance />}
                ></Route>
                <Route
                    path="/register/:referCode?"
                    element={<Register />}
                    // element={<Login />}
                    // element={<Maintainance />}
                ></Route>
                {/* <Route path="/otp/:otp" element={<OTP />}></Route> */}
                {/* <Route path="/otp/:emailId" element={<OTP />}></Route> */}
                <Route path="/otp" element={<OTP />}></Route>

                <Route element={<PrivateRoutes />}>
                    <Route path="/" element={<LandingPage />}></Route>
                    <Route path="/home" element={<LandingPage />}></Route>
                    <Route
                        path="/battle/:type/:id"
                        element={<PlayArea />}
                    ></Route>
                    <Route path="/wallet" element={<Wallet />}></Route>;
                    <Route
                        path="/wallet/addWallet"
                        element={<AddWallet />}
                    ></Route>
                    <Route path="/history" element={<History />}></Route>
                    <Route path="/support" element={<Support />}></Route>
                    <Route path="/payment" element={<PrePayment />}></Route>
                    <Route path="/profile" element={<Profile />}></Route>
                    <Route path="/room/:id" element={<Room />}></Route>
                    <Route path="/Withdraw" element={<Withdrawal />}></Route>
                    <Route
                        path="/WithdrawalUPI"
                        element={<WithdrawalUPI />}
                    ></Route>
                    <Route
                        path="/WithdrawalBank"
                        element={<WithdrawalBank />}
                    ></Route>
                    <Route
                        path="/refer&earn"
                        element={<ReferAndEarn />}
                    ></Route>
                    <Route path="/policy" element={<Policy />}></Route>
                    <Route path="/kyc" element={<Kyc />}></Route>
                    <Route
                        path="/wallet/addWallet/upi-payment"
                        element={<UpiPayment />}
                    ></Route>
                    <Route
                        path="/wallet/addWallet/qr-payment"
                        element={<QRPayment />}
                    ></Route>
                    <Route
                        path="/payment-status"
                        element={<PaymentStatus />}
                    ></Route>
                </Route>
            </Routes>
        </Suspense>
    );
};

export default Routing;

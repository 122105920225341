import { useNavigate } from 'react-router';
import User from '../../assets/UserProfile';
import vsImg from '../../assets/VS.webp';
import { useEffect, useState } from 'react';
import Loader from '../Common/Loader/Loader';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import {
    useAcceptChallenge,
    useCreateBattle,
    useChangeBattleStatus,
} from '../../apis/hooks/useUpdatePlayerBattleResponse';
import { Tooltip } from 'react-tooltip';
import useDeleteBattle from '../../apis/hooks/useDeleteBattle';
import { useUserData } from '../../Context/UserContext';
import {
    useGetAllBattle,
    useGetBattle,
    useUpdateBattle,
} from '../../apis/hooks/useBattles';
import AlertSection from '../Common/AlertSection/AlertSection';
import { io } from 'socket.io-client';
import { useSocket } from '../../Context/SocketContext';
import { BATTLE_STATUS } from '../../Common/constants';
import { useSound } from '../../apis/hooks/useSound';
import ViewBattleDetails from '../ViewBattleDetails/ViewBattleDetails';

const PlayBox = ({ message }: { message: any }) => {
    const navigate = useNavigate();
    const { user } = useUserData();
    const { playSound } = useSound();

    const [noBattlesFound, setNoBattlesFound] = useState(false);
    const [battleData, setBattleData] = useState<any>(null);
    const [showBattleDetails, setShowBattleDetails] = useState(false);
    const [myResult, setMyResult] = useState<string | null>(null);

    const [amount, setAmount] = useState('');
    const { type, id: battle_id } = useParams();

    const [battles, setBattles] = useState<any>([]);

    const { data: battlesData, refetch, isLoading } = useGetAllBattle();
    const [sortedBattles, setSortedBattles] = useState<any>([]);

    const { mutate: createBattle, isLoading: createBattleLoading } =
        useCreateBattle();
    const { mutate: changeBattleStatus, isLoading: changingStatus } =
        useChangeBattleStatus();
    const { mutate: acceptChallenge, isLoading: acceptChallengeLoading } =
        useAcceptChallenge();
    const { mutate: deleteBattle, isLoading: deleting } = useDeleteBattle();
    const { mutate: cancelBattleStatus } = useUpdateBattle();
    // const { socket } = useSocket();

    const fakeBattles: any = [
        {
            id: 'fake1_4uow1nvvxzdjogx3bmw0thyw9',
            battleTypeId: 'b744r8hyegddoqfng6r9z2hzz',
            challenger: {
                id: 'fake1_ti7qq04g1dy0x4ppgfejx8usj',
                username: 'ABCD',
            },
            acceptor: {
                id: 'fake1_qskf9o274nqj72ko6q0e4i9wz',
                username: 'WXYZ',
            },
            amount: 250,
        },
        {
            id: 'fake2_4uow1nvvxzdjogx3bmw0thyw9',
            battleTypeId: 'b744r8hyegddoqfng6r9z2hzz',
            challenger: {
                id: 'fake2_ti7qq04g1dy0x4ppgfejx8usj',
                username: 'Aadil khan',
            },
            acceptor: {
                id: 'fake2_qskf9o274nqj72ko6q0e4i9wz',
                username: 'Sohail',
            },
            amount: 100,
        },
        {
            id: 'fake3_4uow1nvvxzdjogx3bmw0thyw9',
            battleTypeId: 'b744r8hyegddoqfng6r9z2hzz',
            challenger: {
                id: 'fake3_ti7qq04g1dy0x4ppgfejx8usj',
                username: 'नो फ्रेश आईडी',
            },
            acceptor: {
                id: 'fake3_qskf9o274nqj72ko6q0e4i9wz',
                username: 'FAST UPDATE',
            },
            amount: 500,
        },
        {
            id: 'fake4_4uow1nvvxzdjogx3bmw0thyw9',
            battleTypeId: 'b744r8hyegddoqfng6r9z2hzz',
            challenger: {
                id: 'fake4_ti7qq04g1dy0x4ppgfejx8usj',
                username: 'रात का सपना',
            },
            acceptor: {
                id: 'fake4_qskf9o274nqj72ko6q0e4i9wz',
                username: 'नींद ना आए',
            },
            amount: 150,
        },
        {
            id: 'fake5_4uow1nvvxzdjogx3bmw0thyw9',
            battleTypeId: 'b744r8hyegddoqfng6r9z2hzz',
            challenger: {
                id: 'fake5_ti7qq04g1dy0x4ppgfejx8usj',
                username: 'Rakesh Meena',
            },
            acceptor: {
                id: 'fake5_qskf9o274nqj72ko6q0e4i9wz',
                username: 'supyan khan',
            },
            amount: 350,
        },
    ];

    useEffect(() => {
        if (battles) {
            const userId = user?.userdetails?.id;

            const sortedData = battles.sort((a: any, b: any) => {
                const isUserChallengerA = a.challenger?.id === userId;
                const isUserAcceptorA = a.acceptor?.id === userId;
                const isUserInBattleA = isUserChallengerA || isUserAcceptorA;

                const isUserChallengerB = b.challenger?.id === userId;
                const isUserAcceptorB = b.acceptor?.id === userId;
                const isUserInBattleB = isUserChallengerB || isUserAcceptorB;

                // 1. Prioritize battles where the user is the challenger
                if (isUserChallengerA && !isUserChallengerB) return -1;
                if (isUserChallengerB && !isUserChallengerA) return 1;

                // 2. Battles where the user is either the challenger or acceptor
                if (isUserInBattleA && !isUserInBattleB) return -1;
                if (isUserInBattleB && !isUserInBattleA) return 1;

                // 3. New pending battles where the user is not a participant
                if (
                    !isUserInBattleA &&
                    a.status === 'Pending' &&
                    (!isUserInBattleB || b.status !== 'Pending')
                )
                    return -1;
                if (
                    !isUserInBattleB &&
                    b.status === 'Pending' &&
                    (!isUserInBattleA || a.status !== 'Pending')
                )
                    return 1;

                // 4. For other battles, sort by creation date, newest first
                return (
                    Date.parse(b.created_at.toString()) -
                    Date.parse(a.created_at.toString())
                );

                // 4. Remaining battles (no specific sorting for these)
                // return 0;
            });

            setSortedBattles(sortedData);
        }
    }, [battles]);

    useEffect(() => {
        // if (battlesData?.length) {
        setBattles(battlesData);
        // } else {
        // setNoBattlesFound(true);
        // }
    }, [battlesData]);

    const isAmountValid = (amount: string) => {
        return (
            amount.trim() !== '' &&
            Number(amount) % 50 === 0 &&
            Number(amount) <= 10000
        );
    };

    const onSubmit = async () => {
        if (
            isAmountValid(amount) &&
            parseInt(amount) >= 50 &&
            parseInt(amount) <= 10000
        ) {
            const payload = {
                battleTypeId: battle_id,
                challengerId: user?.userdetails?.id,
                amount: parseInt(amount),
            };

            createBattle(payload, {
                onSuccess: (res: any) => {
                    if (res?.data?.success) {
                        setAmount('');
                        // socket.emit('newMessage', {
                        //     message: 'Battle Created Successfully',
                        //     data: res?.data?.data,
                        // });
                        refetch();

                        // toast('Battle Created Successfully', {
                        //     type: 'success',
                        // });
                    }
                    if (res?.data?.error) {
                        toast(res?.data?.message ?? 'Unable to create battle', {
                            type: 'error',
                        });
                    }
                },
                onError: (error: any) => {
                    toast(error?.message ?? 'Something went wrong', {
                        type: 'error',
                    });
                },
            });
        } else {
            toast(
                'please enter valid values min 50 and max 10,000 i.e 50,100,150 etc',
                {
                    type: 'error',
                }
            );
        }
    };

    const handleDelete = (id: string) => {
        const data = {
            battle_id: id,
        };
        deleteBattle(data, {
            onSuccess: (res: any) => {
                if (res?.data?.success) {
                    refetch();
                }
            },
            onError: (e: any) => {
                if (e) {
                    toast(e?.message || 'Try Again!', { type: 'error' });
                }
            },
        });
    };

    const handleAcceptorResponse = (battle: any) => {
        const acceptorId = user?.userdetails?.id;

        const payload = {
            battleId: battle?.id,
            acceptorId,
            amount: battle?.amount,
        };

        acceptChallenge(payload, {
            onSuccess: (data: any) => {
                if (data?.data?.error) {
                    toast(data?.data?.message, { type: 'error' });
                } else {
                    // socket.emit('newMessage', {
                    //     message: 'Challenge Accepted Successfully',
                    //     data: { id: battle?.id },
                    // });
                    refetch();
                    // toast(data?.data?.message, { type: 'success' });
                }
            },
            onError: (error: any) => {
                toast(
                    error?.message ? error?.message : 'Something went wrong',
                    { type: 'error' }
                );
            },
        });
    };

    const handleBattleStatus = (battle: any, status: BATTLE_STATUS) => {
        const payload = {
            battleId: battle?.id,
            status,
            userId: user?.userdetails?.id,
        };
        changeBattleStatus(payload, {
            onSuccess: (data: any) => {
                if (data?.data?.error) {
                    toast(data?.data?.message, { type: 'error' });
                } else {
                    // socket.emit('newMessage', {
                    //     message: 'Battle Status Changed Successfully',
                    //     data: { id: battle?.id },
                    // });
                    if (status !== BATTLE_STATUS.CANCELLED) {
                        redirectToRoomCode(battle);
                    }
                    refetch();
                    toast(
                        data?.data?.message ??
                            'Battle Status Changed Successfully',
                        { type: 'success' }
                    );
                    // toast(data?.data?.message, { type: 'success' });
                }
            },
            onError: (error: any) => {
                refetch();
                toast(error?.message ?? 'Something went wrong', {
                    type: 'error',
                });
            },
        });
    };

    const redirectToRoomCode = (battle: any) => {
        navigate(`/room/${battle?.id}`);
    };

    useEffect(() => {
        console.log('PlayBox Message: ', message);
        const action = message?.data?.action;
        switch (action) {
            case 'create':
                break;
            case 'delete':
                break;
            case 'accept':
                if (
                    message?.data?.data?.challenger?.id ===
                    user?.userdetails?.id
                ) {
                    playSound();
                }
                break;
            case 'changeBattleToViewStatus':
                if (
                    message?.data?.data?.acceptor?.id === user?.userdetails?.id
                ) {
                    playSound();
                }
                break;
            case 'roomCodeSend':
                break;

            default:
                break;
        }
        refetch();
    }, [message]);

    const handleViewClicked = (battle: any) => {
        const isIamChallenger =
            battle?.challenger?.id === user?.userdetails?.id;
        const isIamAcceptor = battle?.acceptor?.id === user?.userdetails?.id;

        if (isIamChallenger && battle?.challengerResponse) {
            const viewDetails = {
                ...battle,
                playerResponse: battle?.challengerResponse,
            };
            setBattleData(viewDetails);
            setShowBattleDetails(true);
        } else if (isIamAcceptor && battle?.acceptorResponse) {
            const viewDetails = {
                ...battle,
                playerResponse: battle?.acceptorResponse,
            };
            setBattleData(viewDetails);
            setShowBattleDetails(true);
        } else {
            redirectToRoomCode(battle);
        }
        // if (!result) {
        //     redirectToRoomCode(battle);
        //     return;
        // } else {
        //     setMyResult(result);
        //     setBattleData(battle);
        //     setShowBattleDetails(true);
        // }
    };

    const renderBattleOnGoing = (battle: any, index: number) => {
        const isChallenger = battle?.challenger?.id === user?.userdetails?.id;
        const isAcceptor = battle?.acceptor?.id === user?.userdetails?.id;

        return (
            <>
                <div className="bg-[#F8F9FA] p-2 flex justify-between border-b rounded border slide-in-left">
                    <span className="">Challenge set by</span>
                    <span className="font-bold text-[#198754]">
                        Rs {battle?.amount}
                    </span>
                </div>
                <div className="flex justify-between p-2 border border-t-0 slide-in-left">
                    <div className="flex items-center gap-2 font-semibold">
                        <User className="w-[28px] h-[28px] rounded-xl border" />

                        {(() => {
                            switch (true) {
                                case isChallenger &&
                                    battle?.status === BATTLE_STATUS.PENDING:
                                    return (
                                        <span className="flex gap-2">
                                            <Loader /> Finding player
                                        </span>
                                    );

                                case isChallenger &&
                                    battle?.status === BATTLE_STATUS.PLAYING &&
                                    !battle?.roomCode:
                                    return (
                                        <span className="flex gap-2">
                                            {battle?.acceptor?.username?.slice(
                                                0,
                                                8
                                            ) + '..'}
                                        </span>
                                    );

                                case isAcceptor &&
                                    battle?.status === BATTLE_STATUS.PLAYING &&
                                    !battle?.roomCode:
                                    return (
                                        <span className="flex gap-2">
                                            {battle?.challenger?.username?.slice(
                                                0,
                                                8
                                            ) + '..'}
                                        </span>
                                    );

                                case !isChallenger &&
                                    !isAcceptor &&
                                    battle?.status === 'Ready' &&
                                    !battle?.roomCode:
                                    return (
                                        <span className="flex gap-2">
                                            {battle?.acceptor?.username?.slice(
                                                0,
                                                8
                                            ) + '..'}
                                        </span>
                                    );

                                case isChallenger &&
                                    battle?.status === 'View' &&
                                    !battle?.roomCode:
                                    return (
                                        <span className="flex gap-2">
                                            {battle?.acceptor?.username?.slice(
                                                0,
                                                8
                                            ) + '..'}
                                        </span>
                                    );

                                case isAcceptor &&
                                    battle?.status === 'View' &&
                                    !battle?.roomCode:
                                    return (
                                        <span className="flex gap-2">
                                            {battle?.challenger?.username?.slice(
                                                0,
                                                8
                                            ) + '..'}
                                        </span>
                                    );

                                default:
                                    return (
                                        <span className="flex gap-2">
                                            {battle?.challenger?.username?.slice(
                                                0,
                                                8
                                            ) + '..'}
                                        </span>
                                    );
                            }
                        })()}
                    </div>

                    {isChallenger &&
                        battle?.status === BATTLE_STATUS.PENDING && (
                            <button
                                className="px-2 py-1 text-white bg-red-600 rounded"
                                onClick={() => handleDelete(battle?.id)}
                                disabled={deleting}
                            >
                                Delete
                            </button>
                        )}

                    {!isChallenger &&
                        battle?.status === BATTLE_STATUS.PENDING && (
                            <button
                                className="px-2 py-1 text-white bg-[#0d6efd] rounded flex"
                                onClick={() => handleAcceptorResponse(battle)}
                                disabled={acceptChallengeLoading}
                            >
                                {acceptChallengeLoading ? (
                                    <Loader scale={1} color="white" />
                                ) : (
                                    'Play'
                                )}
                            </button>
                        )}

                    {isChallenger &&
                        battle?.status === BATTLE_STATUS.PLAYING &&
                        !battle?.roomCode && (
                            <div className="flex gap-2">
                                <button
                                    className="flex px-2 py-1 text-white bg-[#0d6efd] rounded"
                                    onClick={() =>
                                        handleBattleStatus(
                                            battle,
                                            BATTLE_STATUS.VIEW
                                        )
                                    }
                                    disabled={changingStatus}
                                >
                                    {changingStatus ? (
                                        <Loader scale={1} color="white" />
                                    ) : (
                                        'Play'
                                    )}
                                </button>
                                <button
                                    className="px-2 py-1 text-white bg-red-600 rounded"
                                    onClick={() =>
                                        handleBattleStatus(
                                            battle,
                                            BATTLE_STATUS.CANCELLED
                                        )
                                    }
                                    disabled={changingStatus}
                                >
                                    {changingStatus ? (
                                        <Loader scale={1} color="white" />
                                    ) : (
                                        'Cancel'
                                    )}
                                </button>
                            </div>
                        )}

                    {isAcceptor &&
                        battle?.status === BATTLE_STATUS.PLAYING &&
                        !battle?.roomCode && (
                            <div className="flex gap-2">
                                <button
                                    className="px-1 border border-black rounded border-1"
                                    // onClick={() => redirectToRoomCode(battle)}
                                >
                                    Request
                                </button>
                                <button
                                    className="px-1 text-white bg-red-600 rounded"
                                    onClick={() =>
                                        handleBattleStatus(
                                            battle,
                                            BATTLE_STATUS.CANCELLED
                                        )
                                    }
                                    disabled={changingStatus}
                                >
                                    {changingStatus ? (
                                        <Loader scale={1} color="white" />
                                    ) : (
                                        'Reject'
                                    )}
                                </button>
                            </div>
                        )}

                    {/* roomCode means battle Status is Playing, DRAW,  other cases are going on completed battle, like cancelled, Finished*/}
                    {((isChallenger || isAcceptor) && battle?.roomCode) ||
                    battle?.status === BATTLE_STATUS.VIEW ? (
                        <div className="flex gap-2">
                            <button
                                className="px-2 py-1 text-white bg-green-600 rounded"
                                onClick={() => handleViewClicked(battle)}
                            >
                                View
                            </button>
                        </div>
                    ) : null}
                </div>
            </>
        );
    };

    const renderCompletedBattle = (battle: any, index: number) => {
        return (
            <div
                className="p-4 mb-6 font-semibold transition ease-in-out transform border rounded-md"
                onClick={() => {
                    if (
                        battle?.challenger?.id === user?.userdetails?.id ||
                        battle?.acceptor?.id === user?.userdetails?.id
                    ) {
                        // redirectToRoomCode(battle);
                        handleViewClicked(battle);
                    }
                }}
            >
                <div className="flex items-center justify-between">
                    {/* Challenger section */}
                    <div
                        className="flex items-center gap-2 cursor-pointer"
                        data-tooltip-id="challengerUsername"
                        data-tooltip-content={battle?.challenger?.username}
                    >
                        <User className="w-[28px] h-[28px] rounded-xl border" />
                        <p className="w-[70px]">
                            {battle?.challenger?.username?.slice(0, 5) + '...'}
                        </p>
                    </div>

                    {/* VS image and amount section */}
                    <div className="flex items-center">
                        <img
                            src={vsImg}
                            className="w-[30px] h-10"
                            alt="vsImg"
                        />
                    </div>

                    {/* Acceptor section */}
                    <div
                        className="flex items-center gap-2 cursor-pointer"
                        data-tooltip-id="acceptorUsername"
                        data-tooltip-content={battle?.acceptor?.username}
                    >
                        <p className="w-[70px]">
                            {battle?.acceptor?.username?.slice(0, 5) + '...'}
                        </p>
                        <User className="w-[28px] h-[28px] rounded-xl border" />
                    </div>
                </div>
                <div className="flex justify-center">
                    <span className="font-bold text-[#198754] pt-2">
                        Rs {battle?.amount}
                    </span>
                </div>

                {/* Tooltip for challenger and acceptor usernames */}
                <Tooltip id="challengerUsername" />
                <Tooltip id="acceptorUsername" />
            </div>
        );
    };

    const ComingSoon = () => {
        return (
            <div className="text-center text-[24px] font-semibold">
                Coming Soon....
            </div>
        );
    };

    const renderBattleCard = (battle: any, index: number) => {
        const handleReadyBattle = () => {
            if (
                battle?.challenger?.id !== user?.userdetails?.id &&
                battle?.acceptor?.id !== user?.userdetails?.id
            ) {
                return renderCompletedBattle(battle, index);
            } else {
                return renderBattleOnGoing(battle, index);
            }
        };

        switch (battle?.status) {
            case 'Pending':
                return renderBattleOnGoing(battle, index);
            case 'Play':
            case 'Playing':
            case 'Draw':
            case 'Ready':
            case 'Semi Cancelled':
            case 'View':
                return handleReadyBattle();

            case 'Finished':
            case 'Cancelled':
                return renderCompletedBattle(battle, index);

            // case 'Ready':
            //     return handleReadyBattle();

            default:
                return ComingSoon();
        }
    };

    return (
        <section className="mx-auto w-[90%]">
            <AlertSection
                message="सभी खिलाड़ी उसी नाम से KYC करें, जिस नाम से पैसे डालेंगे। यदि KYC के अलावा किसी और नाम से पैसे डालते हैं, तो आपका पैसा होल्ड हो जाएगा।"
                className="p-2 my-2 text-xs text-black bg-yellow-300"
                alertType="WARNING"
            />
            <br />
            <AlertSection
                message="सभी खिलाड़ियों को withdraw में समस्या होने के लिए खेद है। अब आपको तुरंत withdraw or support अच्छा मिलेगा। अपना प्यार और विश्वास बनाए रखें। 🙏🚀"
                className="p-2 my-2 text-xs text-black bg-gray-300"
                alertType="INFO"
            />
            <div className="py-4 rounded ">
                <div className="flex justify-between rounded h-[38px] gap-2 ">
                    <input
                        value={amount}
                        type="text"
                        inputMode="numeric"
                        placeholder="Amount"
                        className="w-full pl-4 border-2 border-gray-300 rounded h-[38px] focus:outline-none focus:border-blue-500"
                        onChange={(e: any) => setAmount(e.target.value.trim())}
                    />
                    <button
                        className={`w-[120px] rounded text-white ${
                            !isAmountValid(amount) || createBattleLoading
                                ? 'bg-gray-400 cursor-not-allowed opacity-60'
                                : 'bg-blue-600 hover:bg-blue-700 cursor-pointer'
                        }`}
                        disabled={!isAmountValid(amount) || createBattleLoading}
                        onClick={() => onSubmit()}
                    >
                        Set
                    </button>
                </div>
            </div>

            <div className="flex justify-center w-full mt-2">
                <span className="text-center mx-1 font-bold text-[#198754] mb-4">
                    🏆︎ Open Battles(Classic)🏆︎
                </span>
            </div>

            {isLoading && (
                <div className="flex flex-col gap-8 items-center justify-center h-[300px]">
                    <Loader scale={4} />
                    <p>Please Wait. Loading...</p>
                </div>
            )}

            {
                sortedBattles?.length
                    ? sortedBattles?.map((battle: any, index: any) => (
                          <div
                              className="mb-6 transition ease-in-out transform"
                              key={index}
                          >
                              {renderBattleCard(battle, index)}
                          </div>
                      ))
                    : null
                // <div className="flex flex-col gap-8 items-center justify-center h-[200px] font-semibold text-xl">
                //     Curretly No Open Battles Present
                // </div>
            }

            {/* FAKE BAttles */}
            {fakeBattles?.map((battle: any, index: any) => (
                <div
                    className="mb-6 transition ease-in-out transform"
                    key={index}
                >
                    {renderCompletedBattle(battle, index)}
                </div>
            ))}

            {showBattleDetails && (
                <ViewBattleDetails
                    isOpen={showBattleDetails}
                    battleData={battleData ?? null}
                    // myResult={myResult ?? null}
                    onClose={() => setShowBattleDetails(false)}
                />
            )}
        </section>
    );
};

export default PlayBox;

import { useMutation, useQuery } from '@tanstack/react-query';
import { Client } from '../index';
import { API_PATHS } from '../constants';
import { useUserData } from '../../Context/UserContext';

export const useGetAllBattle = () => {
    const { user } = useUserData();
    const { isLoading, data, refetch } = useQuery(
        ['get-all-battle'], // Add the id to the query key
        () => {
            // Use a function to call the API with the id
            return Client.get(API_PATHS.GET_ALL_BATTLE, {
                headers: {
                    userId: user?.userdetails?.id ?? '',
                },
				params:{
					limit:50
				}
            }).then((res: any) => {
                return res?.data?.data ?? null; // Return the response data
            });
        }
    );

    return {
        isLoading,
        data,
        refetch,
    };
};

export const useGetBattle = ({ id }: { id: string }) => {
    const { isLoading, data, refetch } = useQuery(
        ['get-single-battle', id], // Add the id to the query key
        () => {
            // Use a function to call the API with the id
            return Client.get(API_PATHS.GET_BATTLE.replace('{id}', id)).then(
                (res: any) => {
                    return res?.data?.data ?? null; // Return the response data
                }
            );
        },
        {
            enabled: !!id, // Only run the query if id is not empty
        }
    );

    return {
        isLoading,
        data,
        refetch,
    };
};

export const useShareRoomCode = ({ id }: { id: string }) => {
    const { isLoading, mutate } = useMutation(
        [`share-room-code`, id],
        (data: any) => {
            return Client.post(API_PATHS.SEND_ROOM_CODE, data);
        }
    );
    return {
        isLoading,
        mutate,
    };
};

export const useUpdateBattle = () => {
    const { isLoading, mutate } = useMutation(
        [`update-battle`],
        (data: any) => {
            return Client.post(API_PATHS.UPDATE_BATTLE_STATUS, data);
        }
    );
    return {
        isLoading,
        mutate,
    };
};
